import React from "react";
import { Link } from "gatsby";

import { Container, Row, Col } from "reusecore/Layout";
import SectionTitle from "reusecore/SectionTitle";
import Button from "reusecore/Button";
import VintageBox from "reusecore/VintageBox";

import integrationImage1 from "assets/images/app/integrations/integration-1.png";
import integrationImage2 from "assets/images/app/integrations/integration-2.png";
import integrationImage3 from "assets/images/app/integrations/integration-3.png";
import integrationImage4 from "assets/images/app/integrations/integration-4.png";
import integrationImage5 from "assets/images/app/integrations/integration-5.png";
import integrationImage6 from "assets/images/app/integrations/integration-6.png";
import Icon from "assets/images/app/integrations/arrow.png";

import IntegrationItemWrapper from "./integrationSection.style";

const Integrations = () => {
    return (
        <IntegrationItemWrapper>
            <Container>
                <Row Vcenter={true}>
                    <Col xs={12} sm={6}>
                        <div className="integration__block__wrap">
                            <div className="integration__block">
                                <div className="integration__block__inner">
                                    <img src={integrationImage1} alt="appion integration" />
                                    <h3>Mail Chimp</h3>
                                    <p>Send Emails</p>
                                    <Link to="#">
                                        <img src={Icon} alt="appion app" />
                                    </Link>
                                </div>
                                <div className="integration__block__inner">
                                    <img src={integrationImage2} alt="appion integration" />
                                    <h3>Slack</h3>
                                    <p>Messaging</p>
                                    <Link to="#">
                                        <img src={Icon} alt="appion app" />
                                    </Link>
                                </div>
                            </div>
                            <div className="integration__block right">
                                <div className="integration__block__inner">
                                    <img src={integrationImage5} alt="appion integration" />
                                    <h3>Office 365</h3>
                                    <p>Collaboration</p>
                                    <Link to="#">
                                        <img src={Icon} alt="appion app" />
                                    </Link>
                                </div>
                                <div className="integration__block__inner">
                                    <img src={integrationImage6} alt="appion integration" />
                                    <h3>Salesforce</h3>
                                    <p>CRM</p>
                                    <Link to="#">
                                        <img src={Icon} alt="appion app" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} className="integration-content-wrap">
                        <SectionTitle
                            className="section-title"
                            leftAlign={true}
                            UniWidth="100%"
                        >
                            <h4>Integration</h4>

                            <h2>
                                <span>Uses for this app </span> In the perfect integration.
                            </h2>
                        </SectionTitle>
                        <p className="integration-text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
                        </p>
                        <VintageBox 
                            right={true}
                            vintageTwo={true}
                            position="relative"
                        >
                            <Button className="integration-btn">Learn More</Button>
                        </VintageBox>
                    </Col>
                </Row>
            </Container>
        </IntegrationItemWrapper>
    );
};

export default Integrations;
